import formatThings from "@/mixins/general/formatThings";

import balanceCardImg from "@/assets/images/dashboard/banks/balance-card.png";
import debitCardIncomingImg from "@/assets/images/dashboard/banks/add-debit-card.png";
import debitCardImg from "@/assets/images/dashboard/banks/minus-debit-card.png";

export default {
  mixins: [formatThings],
  data() {
    return {
      bankTransaction: {
        loading: false,
        details: {},
        list: [],
        meta: {},
        filters: {
          id: "",
          bankId: "",
          documentId: "",
          users: {
            selected: [],
          },
          status: {
            selected: [],
          },
          types: {
            list: [
              { label: "Crédito", value: "credit" },
              { label: "Débito", value: "debit" },
            ],
            selected: [],
          },
          paymentMethods: {
            list: [
              {
                label: "Transferência Bancária",
                value: "Transferência Bancária",
              },
              { label: "Pix", value: "Pix" },
            ],
            selected: [],
          },
          productSupplier: undefined,
          companies: {
            list: [],
            selected: undefined,
          },
          companyBranches: {
            list: [],
            selected: [],
          },
          period: {
            selected: [],
          },
          order: "desc",
          orderBy: "created",
        },
        pagination: {
          page: 1,
          perPage: 20,
          total: 0,
          complete_list_total: 0,
          totalPages: 0,
        },
      },
      valuePanelsArr: [
        {
          name: "Saldo",
          extra: "Mês Anterior: R$ 0,00",
          total: "R$ 0,00",
          img: balanceCardImg,
          width: 26,
          color: "#008AE0",
        },
        {
          name: "Créditos",
          extra: "",
          total: "R$ 0,00",
          img: debitCardIncomingImg,
          width: 26,
          color: "#00BD9B",
        },
        {
          name: "Débitos",
          extra: "",
          total: "R$ 0,00",
          img: debitCardImg,
          width: 26,
          color: "#E33D53",
        },
      ],
      bankTransactionsTableColumns: [
        {
          title: "Data",
          scopedSlots: { customRender: "date" },
          key: "date",
          width: "10%",
          sorter: true,
        },
        {
          title: "Doc/Evento",

          scopedSlots: { customRender: "document_id" },
          key: "document_id",
          width: "12%",
          sorter: true,
        },
        {
          title: "Nome do evento",
          scopedSlots: { customRender: "event" },
          width: "24%",
        },
        {
          title: "Tipo",
          scopedSlots: { customRender: "payment_method" },
          width: "18%",
          sorter: true,
          key: "payment_method",
        },
        {
          title: "Fornecedor/Cliente",
          scopedSlots: { customRender: "product_supplier" },
          width: "16%",
        },
        {
          title: "Valor",
          scopedSlots: { customRender: "value" },
          width: "10%",
          key: "value",
          sorter: true,
        },
        {
          title: "",
          scopedSlots: { customRender: "type" },
          width: "2%",
        },
        {
          title: "",
          scopedSlots: { customRender: "expand" },
          width: 80,
        },
      ],
      loadingCreateTransaction: false,
    };
  },
  methods: {
    changeBankTransactionPage(current) {
      this.bankTransaction.pagination.page = current;
      this.getBankTransactions();
    },
    bankTransactionTableChange(pagination, filters, sorter) {
      console.log(filters, pagination, sorter);
      this.bankTransaction.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.bankTransaction.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getBankTransactions();
    },
    bankTransactionChangePageSize(current, pageSize) {
      this.bankTransaction.pagination.page = current;
      this.bankTransaction.pagination.perPage = pageSize;
      this.getBankTransactions();
    },
    bankTransactionFilters() {
      let filters = "",
        queryParams = new URLSearchParams("");
      history.pushState(null, null, "");

      if (this.bankTransaction.filters.bankTransactionCode) {
        filters += `&bankTransaction_code=${this.bankTransaction.filters.bankTransactionCode}`;
        queryParams.set(
          "bankTransaction_code",
          `${this.bankTransaction.filters.bankTransactionCode}`
        );
      }

      if (this.bankTransaction.filters.users.selected.length > 0)
        filters += `&user_id=${this.bankTransaction.filters.users.selected}`;

      if (this.bankTransaction.filters.bankId)
        filters += `&bank_id=${this.bankTransaction.filters.bankId}`;

      if (this.bankTransaction.filters.documentId)
        filters += `&document_id=${this.bankTransaction.filters.documentId}`;

      if (this.bankTransaction.filters.status.selected.length > 0)
        filters += `&status=${this.bankTransaction.filters.status.selected}`;

      if (this.bankTransaction.filters.types.selected.length > 0)
        filters += `&type=${this.bankTransaction.filters.types.selected}`;

      if (this.bankTransaction.filters.paymentMethods.selected.length > 0)
        filters += `&payment_method=${this.bankTransaction.filters.paymentMethods.selected}`;

      if (this.bankTransaction.filters.productSupplier)
        filters += `&product_supplier_id=${this.bankTransaction.filters.productSupplier}`;

      if (this.bankTransaction.filters.companies.selected)
        filters += `&company_id=${this.bankTransaction.filters.companies.selected}`;

      if (this.bankTransaction.filters.companyBranches.selected.length > 0)
        filters += `&company_branch_id=${this.bankTransaction.filters.companyBranches.selected}`;

      if (this.bankTransaction.filters.period.selected.length > 0) {
        filters += `&period=${this.bankTransaction.filters.period.selected[0]}|${this.bankTransaction.filters.period.selected[1]}`;
        queryParams.set(
          "period",
          `${this.bankTransaction.filters.period.selected[0]}|${this.bankTransaction.filters.period.selected[1]}`
        );
      }

      if (this.bankTransaction.filters.id)
        filters += `&id=${this.bankTransaction.filters.id}`;

      filters += `&order=${this.bankTransaction.filters.order}&order_by=${this.bankTransaction.filters.orderBy}`;

      history.replaceState(null, null, "?" + queryParams.toString());

      return filters;
    },
    async createBankTransaction(payload) {
      this.loadingCreateTransaction = true;
      try {
        const data = await this.$hayaApi.post(`/banks-transactions`, payload);
        this.$emit("onCreateTransaction");
        return data;
      } catch (e) {
        e;
      } finally {
        this.loadingCreateTransaction = false;
      }
    },
    async getBankTransactionById(id) {
      try {
        const data = await this.$http.get(`/bank-transaction/details?id=${id}`);
        this.bankTransaction.details = data.data;
        this.tempBankTransaction = data.data.raw;
        return data;
      } catch (error) {
        console.log(error);
        this.$message.error("Houve algum erro ao carregar os dados.");
        this.bankTransaction.details = {};
      }
    },
    async getBankTransactions(samePage) {
      this.bankTransaction.loading = true;
      try {
        const { data } = await this.$hayaApi.get(
          `/banks-transactions?page=${
            this.bankTransaction.pagination.page
          }&per_page=${
            this.bankTransaction.pagination.perPage
          }${this.bankTransactionFilters()}`
        );

        this.bankTransaction.list = data.data;
        this.bankTransaction.meta = data.meta;
        this.bankTransaction.pagination.page = data.meta.next_page;
        this.bankTransaction.pagination.total = data.meta.total;

        this.valuePanelsArr[0].total = this.formatPricePtBr(
          data.totalCredit - data.totalDebit
        );
        this.valuePanelsArr[0].extra = `Mês Anterior: ${this.formatPricePtBr(
          data.totalCreditLastMonth - data.totalDebitLastMonth
        )}`;
        this.valuePanelsArr[1].total = this.formatPricePtBr(data.totalCredit);
        this.valuePanelsArr[2].total = this.formatPricePtBr(data.totalDebit);

        if (!samePage) {
          this.bankTransaction.pagination.page = 1;
        }

        return data;
      } catch (e) {
        console.log(e);
        this.bankTransaction.list = [];
        this.bankTransaction.meta = {
          total: 0,
          total_available: "0,00",
          total_general: 0,
          total_pages: 0,
          total_spent: "0,00",
          total_value: "0,00",
        };
      } finally {
        this.bankTransaction.loading = false;
      }
    },
  },
};
